import clsx from 'clsx'
import { KTIcon } from '../../helpers'

type Props = {
    className?: string
    imgIcon?: string
    bootstrapIcon?: string
    titleClass?: string
    descriptionClass?: string
    iconClass?: string
    imageBg?: string
    title?: string
    description?: string
}

const ModulesWidget = (props: Props) => {
    const { className, imgIcon, bootstrapIcon, titleClass, descriptionClass, iconClass, imageBg, title, description } = props
    return (
        <div className={`card-body d-flex flex-column justify-content-between ${className}`}>
            <div
                className={`mx-auto mb-2 d-flex align-items-center justify-content-center w-150px h-150px bgi-no-repeat bgi-size-contain bgi-position-center ${iconClass}`}
                style={{ backgroundImage: `url(${imageBg})` }}
            >
                {bootstrapIcon ? (
                    <i className={`bi ${bootstrapIcon}`}></i>
                ) : imgIcon ? (
                    <img src={imgIcon} alt="" width="70px" />
                ) : null}
            </div>
            <div className="mb-0">
                <a href="/teste.html" className={`${titleClass}`}>
                    {title}
                </a>
                <div className={`${descriptionClass}`}>{description}</div>
            </div>
        </div>
    )
}

export { ModulesWidget }
