// /* eslint-disable jsx-a11y/anchor-is-valid */
// import {FC} from 'react'
// import {useLayout} from '../core'

// const Footer: FC = () => {
//   const {classes} = useLayout()
//   return (
//     <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
//       {/* begin::Container */}
//       <div
//         className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
//       >
//         {/* begin::Copyright */}
//         <div className='text-dark order-2 order-md-1'>
//           <span className='text-muted fw-semibold me-2'>{new Date().getFullYear()} &copy;</span>
//           <a href='#' className='text-gray-800 text-hover-primary'>
//             Ágape Sistemas
//           </a>
//         </div>
//         {/* end::Copyright */}

//         {/* begin::Nav */}
//         <ul className='menu menu-gray-600 menu-hover-primary fw-semibold order-1'>
//           <li className='menu-item'>
//             <a href='#' className='menu-link ps-0 pe-2'>
//               About
//             </a>
//           </li>
//           <li className='menu-item'>
//             <a href='#' className='menu-link pe-0 pe-2'>
//               Contact
//             </a>
//           </li>
//           <li className='menu-item'>
//             <a href='#' className='menu-link pe-0'>
//               Purchase
//             </a>
//           </li>
//         </ul>
//         {/* end::Nav */}
//       </div>
//       {/* end::Container */}
//     </div>
//   )
// }

// export {Footer}


import React from 'react';

const Footer = () => {
  return (
    <footer id="footer" accessKey="4">
<div className="widget-area text-white" style={{ backgroundImage: `url("/media/patterns/header-bg.jpg")` }}>
        <div className="container">
          <div className="row">
            {/* Localização */}
            <div className="col-md-3 col-sm-6 bottom-sm-30 mt-10">
              <div className="widget">
                <h5 className="top-10 white bottom-10 text-white-50">LOCALIZAÇÃO</h5>
                <div id="content" className="col-md-8">
                  <p>Endereço: Praça Dr. José Maria Paiva Mello, 26 | Bairro Horácio F. Fontes | CEP 49360-000 Boquim-SE</p>
                  <p>Telefone: (79) 3645-1919</p>
                </div>
                <div id="pip-toast">&nbsp;</div>
              </div>
            </div>

            {/* Posts (pode ser preenchido futuramente) */}
            <div className="col-md-3 col-sm-6 bottom-sm-30">
              <div className="widget widget-posts">
                {/* Conteúdo dos posts */}
              </div>
            </div>

            {/* Redes Sociais */}
            <div className="col-md-3 col-sm-6 bottom-sm-30 mt-10">
              <div className="widget widget-twitter">
                <h5 className="widget-title text-white-50 mb-5">
                  <span>Siga-nos</span>
                </h5>
                <SocialLinks />
              </div>
            </div>

            {/* Parceiros */}
            <div className="col-md-3 col-sm-6 bottom-sm-30">
              <div className="widget enews" style={{ marginTop: '30px', textAlign: 'center' }}>
                <PartnerLinks />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Créditos */}
      <Credits />

      {/* Aviso de Cookie */}
      <CookieNotice />
    </footer>
  );
};

// Componente para Redes Sociais
const SocialLinks = () => (
  <>
    <a href="https://www.facebook.com/prefeituradeboquim" target="_blank" rel="noopener noreferrer" className='m-2'>
      <img src="/media/svg/social-logos/facebook.svg" alt="Siga-nos no facebook" width="35px" />
    </a>
    <a href="https://www.instagram.com/pref_boquim" target="_blank" rel="noopener noreferrer" className='m-2'>
      <img src="/media/svg/social-logos/instagram.svg" alt="Siga-nos no instagram" width="35px" />
    </a>
    <a href="https://www.youtube.com/@prefeituradeboquimoficial4637" target="_blank" rel="noopener noreferrer" className='m-2'>
      <img src="/media/svg/social-logos/youtube.svg" alt="Siga-nos no youtube" width="35px" />
    </a>
    <a href="#" target="_blank" rel="noopener noreferrer" className='m-2'>
      <img src="/media/svg/social-logos/linkedin.svg" alt="Siga-nos no linkedin" width="35px" />
    </a>
    <a href="#" target="_blank" rel="noopener noreferrer" className='m-2'>
      <img src="/media/svg/social-logos/twitter.svg" alt="Siga-nos no x" width="35px" />
    </a>
  </>
);

// Componente para Links de Parceiros
const PartnerLinks = () => (
  <>
    <p>
<a href="#" target="_blank" rel="noopener noreferrer">
        <img src="https://homologacao.sitesagapesistemas.com.br/img/logoAgape.png" className="bottom-30" alt="Ágape Sistemas" />
      </a>
    </p>
    <p>
      <a href="http://www.emgetis.se.gov.br/" target="_blank" rel="noopener noreferrer">
        <img src="https://portal.sitesagapesistemas.com.br/agape2/upload/emgetis.jpg" className="bottom-30 flex rounded" alt="emgetis" />
      </a>
    </p>
  </>
);

// Componente de Créditos
// Componente de Créditos com Navbar
const Credits = () => (
  <nav className="navbar navbar-expand-lg bg-body-tertiary">
    <div className="container-fluid">
      <a className="navbar-brand" href="#">Todos os direitos reservados ::</a>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav ms-auto">
          <li className="nav-item">
            <a className="nav-link active" href="?alias=pmboquim&amp;p=inicio">Início</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="?alias=pmboquim&amp;p=dadosmunicipais">O Município</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="?alias=pmboquim&amp;p=licitacoes">Licitações</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="?alias=pmboquim&amp;f=115&amp;p=Legislacao">Legislação</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="?alias=pmboquim&amp;p=esic">e-SIC</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="?alias=pmboquim&amp;p=lgpd">LGPD</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="?alias=pmboquim&amp;p=estrutura">Estrutura Organizacional</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="?alias=pmboquim&amp;p=mapa">Mapa do site</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="?alias=pmboquim&amp;p=api_de_dados">API de Dados</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
);



// Componente de Aviso de Cookie
const CookieNotice = () => (
  <div id="divCookie" className="cookie-notice" style={{ display: 'none' }}>
    <h4 className="title">Aviso de Cookie</h4>
    <div className="content">
      <p>
        Nós armazenamos dados temporariamente para melhorar a sua experiência de navegação e recomendar conteúdo de seu interesse. Ao utilizar nossos serviços, você concorda com tal monitoramento.
      </p>
    </div>
    <a className="btn-cookie-deny" id="btnDeny">NÃO ACEITAR</a>
    <button type="button" className="btn-cookie-accept" id="btnAccept">
      <i className="fa fa-check"></i> ACEITAR
    </button>
  </div>
);

export default Footer;
